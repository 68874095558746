import React, { useState } from 'react';
import './signup.css';

const AddCard = ({ onNext }) => {
  const [NameOnCard, setNameOnCard] = useState('');
  const [CardNumber, setCardNumber] = useState('');
  const [Exp, setExp] = useState('');
  const [CVV, setCVV] = useState('');
  const [BillZip, setBillZip] = useState('');

  const allFieldsCompleted = () => {
	return NameOnCard !== '' && CardNumber !== '' && Exp !== '' && CVV !== '' && BillZip !== '';
  };

  const handleChange = (event, setter) => {
	const value = event.target.value;
	setter(value);
  };

  const formatCardNumber = (input) => {
	// Remove any non-numeric characters from the input
	const numericInput = input.replace(/\D/g, '');
	// Format the input with dashes after every fourth character
	const formattedInput = numericInput.replace(/(\d{4})/g, '$1-');
	// Remove any trailing dash
	const formattedCardNumber = formattedInput.replace(/-$/, '');
	return formattedCardNumber;
  };

  const handleCardNumberChange = (event) => {
	const formattedCardNumber = formatCardNumber(event.target.value);
	setCardNumber(formattedCardNumber);
  };

  return (
	<section className="onboarding-section">
	  <div className="signup-form">
		<form>
		  <h3>Add card</h3>
		  <div className="input-container">
			<label htmlFor="addcard" className="input-label">Name on Card</label>
			<input
			  type="text"
			  id="NameOnCard"
			  value={NameOnCard}
			  onChange={(e) => handleChange(e, setNameOnCard)}
			  className="text-input"
			  placeholder="Name On Card"
			/>
		  </div>

		  <div className="input-container">
			<label htmlFor="CardNumber" className="input-label">Card Number</label>
			<input
			  type="text"
			  id="CardNumber"
			  value={CardNumber}
			  onChange={handleCardNumberChange}
			  className="text-input"
			  placeholder="1234-1234-1234-5678"
			/>
		  </div>

		  <div className="input-container">
			<label htmlFor="Exp" className="input-label">Exp</label>
			<input
			  type="date"
			  id="Exp"
			  value={Exp}
			  onChange={(e) => handleChange(e, setExp)}
			  className="text-input"
			  placeholder="01/28"
			/>
		  </div>

		  <div className="input-container">
			<label htmlFor="CVV" className="input-label">CVV</label>
			<input
			  type="CVV"
			  id="CVV"
			  value={CVV}
			  onChange={(e) => handleChange(e, setCVV)}
			  className="text-input"
			  placeholder="123"
			/>
		  </div>

		  <div className="input-container">
			<label htmlFor="BillZip" className="input-label">Billing Zip</label>
			<input
			  type="BillZip"
			  id="BillZip"
			  value={BillZip}
			  onChange={(e) => handleChange(e, setBillZip)}
			  className="text-input"
			  placeholder="73013"
			/>
		  </div>
		  <div className="onboarding-buttons">
			<button type="button" onClick={onNext} disabled={!allFieldsCompleted()} className="cont-button">
			  Continue
			</button>
		  </div>
		</form>
	  </div>
	  <div className="onboarding-buttons">
		<button type="button" onClick={onNext} className="back-button">← Back</button>
	  </div>
	</section>
  );
};

export default AddCard;
