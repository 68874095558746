import React from 'react';
import './modal.css';
import closeIcon from './img/icon-x.png';


const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
	<div className="modal-overlay" onClick={onClose}>
	  <div className="modal-content" onClick={e => e.stopPropagation()}>
		{children}
		<button className="close-button" onClick={onClose}><img src={closeIcon} alt="Close" /></button>
	  </div>
	</div>
  );
};

export default Modal;
