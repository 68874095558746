import React, { useState } from 'react';
import './MailChimpForm.css'; 
import mailchimpLogo from './img/mailchimp-gdpr.svg'; 
import { Link } from "react-router-dom";
import CheckboxWithIcon from './checkbox';




const MailChimpForm = ({ onClose }) => {
  // State to manage input values and focus
  const [inputs, setInputs] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    portfolio: '',
    linkedIn: '',
  });
  const [focused, setFocused] = useState('');
  const [errorResponse, setErrorResponse] = useState(''); // State for storing error message
  const [successResponse, setSuccessResponse] = useState(''); // State for storing success message

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(prev => ({ ...prev, [name]: value }));
  };

  // Handle focus
  const handleFocus = (event) => {
    setFocused(event.target.name);
  };

  // Handle blur
  const handleBlur = (event) => {
    if (event.target.value === '') {
      setFocused('');
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Simulating form submission
    try {
      // Simulate an API call
      console.log('Form submitted', inputs);
      // Assume the API call is successful and set the success message
      setSuccessResponse('Thank you for subscribing!');
      setErrorResponse(''); // Clear any previous error messages
    } catch (error) {
      // Simulate handling an error during the submission
      setErrorResponse('An error occurred. Please try again.');
      setSuccessResponse(''); // Clear any previous success messages
    }
  };
  
  // Adjust the input's class based on its state
  const getInputClass = (fieldName) => {
    let classes = 'text-input';
    if (inputs[fieldName] && focused === fieldName) {
      classes += ' active filled'; // Keep 'active' when there's content and it's focused
    } else if (inputs[fieldName]) {
      classes += ' filled'; // Only 'filled' if not focused but has content
    } else if (focused === fieldName) {
      classes += ' active'; // Only 'active' if it's focused
    } else {
      classes += ' empty'; // Consider 'empty' if nothing else
    }
    return classes;
  };
  
  const [emailConsent, setEmailConsent] = useState(false);
  const [textConsent, setTextConsent] = useState(false);
  
  const toggleEmailConsent = () => setEmailConsent(!emailConsent);
  const toggleTextConsent = () => setTextConsent(!textConsent);
  
  // Make sure this function is called whenever input focus or content changes



  return (
    <div id="mc_embed_signup">
      <form action="https://app.us14.list-manage.com/subscribe/post?u=f90396787067ef95e779b0966&amp;id=5cc658de7b" 
      method="post" 
      id="mc-embedded-subscribe-form" 
      name="mc-embedded-subscribe-form" 
      className="validate" 
      target="_self" 
      noValidate
      onSubmit={handleSubmit}>
        <div id="mc_embed_signup_scroll">
        <h3>Join Talent Community</h3>
          <div className="indicates-required"><p>We will only send emails when there are new opportunities available. We do not send marketing emails to this community. Thank you!</p>
          </div>
          <div className="mc-field-group pair">
            <div className="field-container">
              <label htmlFor="mce-FNAME" className="input-label">First Name</label>
              <input type="text" placeholder="First" name="FNAME" 
                     className={getInputClass('firstName')} id="mce-FNAME"
                     onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} />
            </div>
            <div className="field-container">
              <label htmlFor="mce-LNAME" className="input-label">Last Name</label>
              <input type="text" placeholder="Last" name="LNAME" 
                     className={getInputClass('lastName')} id="mce-LNAME"
                     onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} />
            </div>
          </div>
          <div className="mc-field-group pair">
          <div className="field-container">
            <label htmlFor="mce-EMAIL" className="input-label">Email Address</label>
            <input type="email" placeholder="email@email.com" name="EMAIL" 
                   className={getInputClass('email')} id="mce-EMAIL" required
                   onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} />
          </div>
          <div className="field-container">
            <label htmlFor="mce-PHONE" className="input-label">Phone Number</label>
            <input type="text" placeholder="294-394-2988" name="PHONE" 
                   className={getInputClass('phone')} id="mce-PHONE"
                   onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} />
          </div>
        </div>
          <div className="mc-field-group pair">
          <div className="field-container">
            <label htmlFor="mce-MMERGE8" className="input-label">Portfolio/Personal Site</label>
            <input type="url" placeholder="https://yoursite.com" name="MMERGE8" 
                   className={getInputClass('portfolio')} id="mce-MMERGE8"
                   onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} />
          </div>
         <div className="field-container">
            <label htmlFor="mce-MMERGE9" className="input-label">LinkedIn Profile URL</label>
            <input type="url" placeholder="https://linkedin.com/in/username" name="MMERGE9" 
                   className={getInputClass('linkedIn')} id="mce-MMERGE9"
                   onFocus={handleFocus} Blur={handleBlur} onChange={handleChange} />
          </div>
          </div>
         <div id="mergeRow-gdpr" className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
         <div className="gdpr_legal">
           <h2>Marketing Permissions</h2>
           <p>We do not sell your data. You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy practices, please visit our <Link to="/privacy-policy">Privacy Policy</Link>.</p><p>Please select all the ways you would like to hear from Barista Bot:</p>
           <CheckboxWithIcon label="Email" checked={emailConsent} onChange={toggleEmailConsent} />
           <CheckboxWithIcon label="Text" checked={textConsent} onChange={toggleTextConsent} />
           
           <div className="mailchimp-legal">
             <img src={mailchimpLogo} alt="Mailchimp Logo" className="mailchimp-logo" />
             <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/terms">Learn more</a> about Mailchimp's privacy practices.</p>
           </div>

            </div>
          </div>
          
        <div className="response" id="mce-error-response" style={{ display: errorResponse ? 'block' : 'none' }}>{errorResponse}</div>
        <div className="response" id="mce-success-response" style={{ display: successResponse ? 'block' : 'none' }}>{successResponse}</div>
         <div className="modal">
         <div className="clear">
            <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="submit-button" />
          </div>
         </div>
        </div>
      </form>
    </div>
  );
};

export default MailChimpForm;
