import React from 'react';
import CheckedIcon from './img/State=Checked.png';
import UncheckedIcon from './img/State=Unchecked.png';

const CheckboxWithIcon = ({ label, checked, onChange }) => {
  return (
    <label className="checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        style={{ display: 'none' }} // Hide the default checkbox
      />
      {checked ? (
        <img src={CheckedIcon} alt="Checked" />
      ) : (
        <img src={UncheckedIcon} alt="Unchecked" />
      )}
      <span>{label}</span>
    </label>
  );
};

export default CheckboxWithIcon;
