import React from 'react';
import './signup.css';

const OnHeader = () => {
  return (
	<header className="onboarding-header">
	  <a href="http://baristabot.app">
		<img src="/static/media/mark.9750060e.svg" className="App-logo" alt="logo"></img>
	  </a>
	</header>
  );
};

export default OnHeader;