import React, { useState, useRef, useEffect } from 'react';
import './signup.css';
import eyeClosedIcon from './img/eye-closed.png';
import eyeOpenIcon from './img/eye-open.png';

const UserInformation = ({ onNext }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false); // State to track password visibility
  const passwordInputRef = useRef(null); // Ref to store the password input element

  // Function to focus the password input field
  const focusPasswordInput = () => {
	if (passwordInputRef.current) {
	  passwordInputRef.current.focus();
	}
  };

  // Effect to focus the password input field when the component mounts
  useEffect(() => {
	focusPasswordInput();
  }, []);

  const allFieldsCompleted = () => {
	return firstName !== '' && lastName !== '' && phone !== '' && email !== '' && password !== '';
  };

  const handleChange = (event, setter) => {
	const { value } = event.target;
	setter(value);
  };

  const togglePasswordVisibility = () => {
	setPasswordVisible(prev => !prev); // Toggle password visibility
  };

  return (
	<section className="onboarding-section">
	  <div className="signup-form">
		<form>
		  <h3>Create your account</h3>
		  <div className="input-container">
			<label htmlFor="firstName" className="input-label">First Name</label>
			<input
			  type="text"
			  id="firstName"
			  value={firstName}
			  onChange={(e) => handleChange(e, setFirstName)}
			  className="text-input"
			  placeholder="First Name"
			/>
		  </div>

		  <div className="input-container">
			<label htmlFor="lastName" className="input-label">Last Name</label>
			<input
			  type="text"
			  id="lastName"
			  value={lastName}
			  onChange={(e) => handleChange(e, setLastName)}
			  className="text-input"
			  placeholder="Last Name"
			/>
		  </div>

		  <div className="input-container">
			<label htmlFor="phone" className="input-label">Phone</label>
			<input
			  type="tel"
			  id="phone"
			  value={phone}
			  onChange={(e) => handleChange(e, setPhone)}
			  className="text-input"
			  placeholder="Phone"
			/>
		  </div>

		  <div className="input-container">
			<label htmlFor="email" className="input-label">Email</label>
			<input
			  type="email"
			  id="email"
			  value={email}
			  onChange={(e) => handleChange(e, setEmail)}
			  className="text-input"
			  placeholder="Email"
			/>
		  </div>

		  <div className="input-container">
			<label htmlFor="password" className="input-label">
			  Password
			  <div className="sub-description">Minimum 12 characters</div>
			</label>
			<div className="password-input-container">
			  <input
				type={passwordVisible ? 'text' : 'password'}
				id="password"
				value={password}
				onChange={(e) => handleChange(e, setPassword)}
				className="text-input"
				placeholder="Password"
				ref={passwordInputRef} // Assign the ref to the input field
			  />
			  <img
				src={passwordVisible ? eyeOpenIcon : eyeClosedIcon}
				alt={passwordVisible ? 'Hide Password' : 'Show Password'}
				className="password-toggle-icon"
				onClick={togglePasswordVisibility}
			  />
			</div>
		  </div>

		  <div className="onboarding-buttons">
			<button type="button" onClick={onNext} disabled={!allFieldsCompleted()} className="cont-button">
			  Continue
			</button>
		  </div>
		</form>
	  </div>
	  <div className="onboarding-buttons">
		<button type="button" onClick={onNext} className="back-button">← Back</button>
	  </div>
	</section>
  );
};

export default UserInformation;
