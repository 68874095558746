import React, { useState, useEffect } from 'react';
import './signup.css'; // Only import the specific CSS for the signup
import OnFooter from './OnFooter';
import UserInformation from './UserInformation';
import AddCard from './addcard';

function SignUp() {
  const [currentStep, setCurrentStep] = useState(1);

useEffect(() => {
	  // When the component mounts, apply the style to the body
	  document.body.style.backgroundColor = '#A5A4A3';
	  document.body.style.margin = '0';
  
	  // When the component unmounts, revert back to the initial style
	  return () => {
		document.body.style.backgroundColor = ''; // Or the default color
		document.body.style.margin = '';
	  };
	}, []); 
	
  const handleContinue = () => {
	setCurrentStep(currentStep + 1); // Move to the next step
  };

  return (
	<>
	  {currentStep === 1 && <UserInformation onNext={handleContinue} />}
	  {currentStep === 2 && <AddCard />}
	  <OnFooter />
	</>
  );
}

export default SignUp;