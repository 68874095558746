import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import './App.css';
import './emoji/EmojiConversations.css'
import './responsive.css';
import TermsOfService from './terms-of-use.js';
import PrivacyPolicy from './privacy-policy.js';
import HomePage from './HomePage'; // Adjust the path as necessary based on your file structure
import Header from './Header';
import MediaKit from './media-kit';
import PitchDeck from './pitch-deck';
import AboutUs from './about';
import CoffeeShop from './coffee-shop';
import './Font.css'; 
import NotFound from './NotFound';
import OnHeader from './OnHeader';
import SignUp from './signup';




  
function App() {
  return (
    <Router>
      <ScrollToTop>
      <div className="App">
       
        <Routes>
          <Route path="/" element={<><Header /><HomePage /></>} />
          <Route path="/terms-of-use" element={<><Header /><TermsOfService  /></>} />
          <Route path="/privacy-policy" element={<><Header /><PrivacyPolicy  /></>} />
          <Route path="/media-kit" element={<><Header /><MediaKit /></>} />
          <Route path="/pitch-deck" element={<><Header /><PitchDeck /></>} />
          <Route path="/about" element={<><Header /><AboutUs /></>} />
          <Route path="/coffee-shop" element={<><Header /><CoffeeShop /></>} />
          <Route path="/signup" element={<><OnHeader /><SignUp /></>} />
          <Route path="*" element={<><Header /><NotFound /></>} />
        </Routes>
      </div>
      </ScrollToTop>
    </Router>
  );
}



export default App;